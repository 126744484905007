define("@ember/-internals/bootstrap/index", ["require"], function (_require) {
  "use strict";

  (function bootstrap() {
    // Bootstrap Node module
    // eslint-disable-next-line no-undef
    if (typeof module === 'object' && typeof module.require === 'function') {
      // tslint:disable-next-line: no-require-imports
      module.exports = (0, _require.default)("ember").default;
    }
  })();
});