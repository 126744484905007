define("ember-initials/utils/initials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(name) {
    let initials = name ? name.trim() : '';
    const words = initials.split(' ');
    if (words.length > 1) {
      const first = capitalizedFirstLetter(words.shift());
      const last = capitalizedFirstLetter(words.pop());
      initials = first + last;
    } else if (words.length === 1) {
      initials = capitalizedFirstLetter(words.shift());
    }
    return initials;
  }

  // Private

  function capitalizedFirstLetter(word) {
    return word ? word[0].toUpperCase() : '';
  }
});