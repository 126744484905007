define("@bagaaravel/ember-data-extensions/serializer", ["exports", "@bagaaravel/ember-data-extensions/-private/utils", "@ember/string"], function (_exports, _utils, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.keyForAttribute = keyForAttribute;
  _exports.keyForRelationship = keyForRelationship;
  _exports.payloadKeyFromModelName = payloadKeyFromModelName;
  _exports.serialize = serialize;
  _exports.shouldSerializeHasMany = shouldSerializeHasMany;
  function keyForAttribute(key) {
    return (0, _string.underscore)(key);
  }
  function keyForRelationship(key) {
    return key;
  }
  function payloadKeyFromModelName(modelName) {
    return (0, _string.classify)(modelName);
  }
  function serialize(serialized, snapshot) {
    const relationshipName = (0, _utils.getRelationshipName)(snapshot.adapterOptions);
    const isSavingRelationship = Boolean(relationshipName);
    if (!isSavingRelationship) {
      return serialized;
    }
    const serializedRelationships = serialized.data.relationships;
    if (serializedRelationships && serializedRelationships[relationshipName]) {
      return serializedRelationships[relationshipName];
    }
    const relationshipDescriptor = (0, _utils.getRelationshipDescriptor)(snapshot.record, relationshipName);
    if (relationshipDescriptor.kind === 'hasMany') {
      return {
        data: []
      };
    }
    return {
      data: null
    };
  }
  function shouldSerializeHasMany(superCheck, snapshot, key, relationshipDescriptor) {
    const isRecordNew = snapshot.record.isNew;
    const isRecordSaving = snapshot.record.isSaving;
    const relationshipName = (0, _utils.getRelationshipName)(snapshot.adapterOptions);
    const shouldSaveRelationship = relationshipName === relationshipDescriptor.key;
    return superCheck && (isRecordNew || !isRecordSaving) || shouldSaveRelationship;
  }
});