define("ember-initials/utils/store", ["exports", "mini-svg-data-uri", "ember-initials/utils/generators/svg"], function (_exports, _miniSvgDataUri, _svg) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Store {
    constructor() {
      _defineProperty(this, "cache", new Map());
      _defineProperty(this, "svgGenerator", new _svg.default());
    }
    get length() {
      return this.cache.size;
    }
    clear() {
      this.cache.clear();
    }
    getItem(properties) {
      const key = JSON.stringify(properties);
      return this.cache.get(key) || this._create(key, properties);
    }
    _create(key, properties) {
      const element = this.svgGenerator.generate(properties);
      const data = (0, _miniSvgDataUri.default)(element);
      return this.cache.set(key, data) && data;
    }
  }
  _exports.default = Store;
});