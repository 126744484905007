define("ember-custom-actions/utils/url-builder", ["exports", "jquery-param"], function (_exports, _jqueryParam) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(url, path, queryParams) {
    let query = (0, _jqueryParam.default)(queryParams);
    let pathUrl = url.charAt(url.length - 1) === '/' ? `${url}${path}` : `${url}/${path}`;
    return query ? `${pathUrl}?${query}` : pathUrl;
  }
});