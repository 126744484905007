define("ember-service-helper/helpers/service", ["exports", "@ember/application", "@ember/component/helper", "@ember/debug"], function (_exports, _application, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ServiceHelper extends _helper.default {
    compute([serviceName, methodName]) {
      const service = (0, _application.getOwner)(this).lookup(`service:${serviceName}`);
      (false && !(service) && (0, _debug.assert)(`The service '${serviceName}' does not exist`, service));
      if (!methodName) return service;
      (false && !(typeof methodName === 'string' || typeof methodName === 'symbol') && (0, _debug.assert)(`If specified, the second parameter must be the name of a method. Received: '${methodName}'`, typeof methodName === 'string' || typeof methodName === 'symbol'));
      const method = service[methodName];
      (false && !(typeof method === 'function') && (0, _debug.assert)(`'${methodName}' is not a method on '${serviceName}'.`, typeof method === 'function'));
      return method.bind(service);
    }
  }
  _exports.default = ServiceHelper;
});