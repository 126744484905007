define("@bagaaravel/ember-data-extensions/-private/utils", ["exports", "@bagaaravel/ember-data-extensions/-private/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRelationshipDescriptor = getRelationshipDescriptor;
  _exports.getRelationshipName = getRelationshipName;
  function getRelationshipDescriptor(record, relationshipName) {
    return record.constructor.relationshipsByName.get(relationshipName);
  }
  function getRelationshipName(adapterOptions) {
    return adapterOptions && adapterOptions[_config.RELATIONSHIP_ADAPTER_OPTION];
  }
});